@teams0: teams0;

.@{teams0}-wrapper {
  height: 430px;

  .@{teams0} {
    padding: 0 54px 0 112px;
    display: flex;
    align-items: flex-end;
    margin-top: 80px !important;

    .banner-anim {
      width: 100%;
      height: 100%;
    }
    .banner-anim-thumb {
      span {
        background: #e9e9e9;
        box-shadow: none;
        &.active {
          background: @primary-color;
        }
      }
    }
    & .queue-anim-leaving {
      position: relative !important;
    }

    &-banner-user-elem {
      height: 100%;
      color: #fff;
      position: relative;
      overflow: hidden;
    }
    &-image {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      overflow: hidden;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      img {
        height: 100%;
      }
    }
    &-content {
      font-size: 12px;
      color: #919191;
      text-align: center;
      width: 80%;
      margin: 8px auto;
    }
    &-h1 {
      font-size: 24px;
      text-align: center;
      width: 80%;
      margin: 24px auto 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .@{teams0}-wrapper {
    min-height: 480px;
    display: block;

    .@{teams0} {
      display: block;
      padding: 0 !important;
    }
    .teams0-content-wrapper {
      padding: 0 54px;
    }
  }
}
