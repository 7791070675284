@content0: content0;

.@{content0}-wrapper {
  min-height: 446px;
  overflow: hidden;
  .@{content0} {
    height: 100%;
    padding: 64px 24px;

    > .title-wrapper {
      margin: 0 auto 64px;

      span {
        z-index: 9;
        font-family: 'DM Serif Display';
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 125%;
        display: inline-block;
        color: #000000;
        position: relative;
      }
    }

    .title-wrapper-text {
      max-width: 426px;
      text-align: center;
      position: relative;
      margin: 0 auto 64px;
    }
    .title-wrapper-text-right {
      text-align: right;
      position: relative;
      max-width: 322px;
      margin-bottom: 60px;
      display: inline-block;
    }
    &-block {
      padding: 0 4%;
      display: inline-block;
      text-align: center;
      min-height: 200px;
      margin-bottom: 24px;
      img {
        width: 100%;
      }

      &-wrapper {
        position: relative;
        height: 100%;
        top: 25%;
        padding: 20px 0;
      }

      &.queue-anim-leaving {
        position: relative !important;
      }

      &-icon1 {
        width: auto;
        height: 122px;
        margin: auto;
        position: relative;
        img {
          width: auto;
          height: 100%;
        }
      }
      &-icon1-img1 {
        position: absolute;
        left: -15%;
        width: auto;
        height: 122px;
      }
      &-icon1-img2 {
        width: auto !important;
        height: 122px !important;
      }
      &-icon1-img3 {
        position: absolute;
        left: -5%;
        width: auto;
        height: 122px;
      }

      &-icon2 {
        width: auto;
        height: 120px;
        margin: auto;
        position: relative;
        img {
          width: auto;
          height: 100%;
        }
      }

      &-title {
        line-height: 32px;
        margin: 12px auto;
        font-family: 'DM Serif Display';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #000000;
      }
      &-desc {
        padding: 0 5px;
        font-family: 'HK Grotesk';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: #000000;
      }

      &-div {
        margin-bottom: 124px !important;
      }
    }
    &-text-icon {
      position: absolute;
      left: 70px;
      width: 388px;
      height: 67.5px;
      z-index: 8;
    }
    &-text-icon1 {
      position: absolute;
      left: -21px;
      width: 252px;
      height: 48px;
      bottom: -7px;
      z-index: 8;
    }
    &-text-icon2 {
      position: absolute;
      width: 201px;
      height: 36px;
      left: 33%;
      top: 23px;
    }
    &-text {
      font-family: 'HK Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      max-width: 990px;
      color: #000000;
      margin: 0 auto 60px;
    }

    &-nopadding {
      padding: 0 auto 58px;
      background: #fffcfa;
    }
  }
  &-color {
    background-color: #fffcfa;
  }
  &-color-img {
    position: absolute;
    width: 533px;
    height: 526px;
  }
  &-color-imgl {
    bottom: 0;
    left: 0;
  }
  &-color-imgr {
    top: 0;
    right: 0;
  }
}
.bgNone {
  background: none !important;
}

@media screen and (max-width: 767px) {
  .@{content0}-wrapper {
    // min-height: 880px;
    .content0-block-icon1-img1 {
      position: relative;
      left: 0;
      width: 100% !important;
    }
    .content0-block-icon1-img3 {
      position: relative;
    }

    .@{content0} {
      height: 100%;
      padding: 44px 24px;

      > .title-wrapper {
        margin: 0 auto 12px;

        span {
          font-size: 24px;
          line-height: 125%;
        }
      }
      .title-wrapper-text {
        margin: 0 auto 12px;
      }
      &-text-icon {
        width: 170px;
        height: 34px;
        left: 110px;
        top: 10px;
      }
      &-text-icon1 {
        position: absolute;
        left: -9px;
        width: 110px;
        height: 20px;
        bottom: 6px;
        z-index: 8;
      }
      &-text {
        font-size: 12px;
        line-height: 150%;
        margin: 0 auto 27px;
      }
      .title-wrapper {
        margin: 0 !important;
      }
      &-block {
        &-desc {
          font-size: 12px;
          line-height: 150%;
        }
        &-icon1-img1 {
          position: absolute;
          left: -5%;
          width: auto;
          height: 122px;
        }
        &-icon1-img2 {
          width: auto !important;
          height: 102px !important;
          margin: auto;
          // position: absolute;
          // left: 50%;
          // margin-left:-100px
        }
        &-icon1-img3 {
          position: absolute;
          left: -5%;
          width: auto;
          height: 122px;
        }
      }
      &-text-icon2 {
        position: absolute;
        width: 120px;
        height: 20px;
        left: 35%;
        top: 6px;
      }
    }
  }
  .bgNone {
    padding: 0 24px !important;
  }
}
