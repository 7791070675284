body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header2 {
  background: #001529;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header2 .home-page {
  padding: 0 24px;
}
.header2-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header2-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header2-logo a {
  display: block;
}
.header2-menu {
  float: right;
}
.header2-menu > .menu-item {
  line-height: 62px;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header2-menu > .menu-item.active,
.header2-menu > .menu-item:hover {
  color: #A01E28;
  border-bottom: 2px solid #A01E28;
}
@media screen and (max-width: 767px) {
  .header2-logo {
    z-index: 101;
  }
  .header2.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header2-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-menu .menu-item {
    display: block;
    width: 100%;
    padding: 0 24px;
    border-bottom: none;
    line-height: 40px;
    height: 40px;
    margin: 4px 0 8px;
  }
  .header2-menu .menu-item.active,
  .header2-menu .menu-item:hover {
    border: none;
    background: #A01E28;
    color: #fff;
  }
  .header2-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header2-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-mobile-menu :first-child {
    margin-top: 0;
  }
  .header2 .open {
    height: auto;
  }
  .header2 .open .header2-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header2 .open .header2-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header2 .open .header2-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header2 .open .header2-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #5f9bf1;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  max-width: 786px;
  position: relative;
  z-index: 99;
  top: 25%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5 .banner5-content-br {
  display: none;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
  position: relative;
}
.banner5-content-span {
  position: relative;
  display: inline-block;
}
.banner5-content-text {
  position: relative;
  z-index: 9;
}
.banner5-content-icon {
  position: absolute;
  left: -1px;
  top: 5px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
.banner5-icon-bg {
  background-color: rgba(0, 0, 0, 0);
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/homebg.svg'), linear-gradient(to top, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
  background-position: center;
  background-repeat: no-repeat;
  height: 150px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.banner5-icon {
  text-align: center;
  font-size: 18px;
  color: #fff;
  position: absolute;
  z-index: 9999;
  left: 50%;
  bottom: 70px;
  margin-left: -12px;
  cursor: pointer;
}
.banner5 .path {
  stroke-dasharray: 1000;
  animation: dash 2s linear;
  animation-fill-mode: forwards;
}
@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.banner5 .banner5-bgimg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.banner5 .banner5-bgimg .l1 {
  animation: slashStar 2s ease-in-out 0.3s infinite;
}
.banner5 .banner5-bgimg .l2 {
  animation: slashStar 2s ease-in-out 1.2s infinite;
}
.banner5 .banner5-bgimg .l3 {
  animation: slashStar 2s ease-in-out 0.5s infinite;
}
.banner5 .banner5-bgimg .l4 {
  animation: slashStar 2s ease-in-out 0.8s infinite;
}
.banner5 .banner5-bgimg .l5 {
  animation: slashStar 2s ease-in-out 1.5s infinite;
}
@keyframes slashStar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slashStar {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media screen and (max-width: 767px) {
  .banner5 {
    height: 241px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: left;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5 .banner5-content-br {
    display: block;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
  .banner5 .banner5-content-icon {
    width: 132px;
    left: -5px;
    top: -20px;
  }
  .banner5-icon-bg {
    height: 40px;
    background-size: 100%;
    bottom: -1px;
    z-index: 9;
  }
  .banner5-icon {
    width: 10px;
    bottom: 0px;
    margin-left: -10px;
  }
  .banner5-icon img {
    width: 100%;
  }
}
.feature7-wrapper {
  min-height: 564px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #f7f9fc;
}
.feature7-wrapper.home-page-wrapper .home-page {
  padding: 40px 24px 73px;
}
.feature7-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.feature7-title-h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.feature7-title-content {
  margin-top: 16px;
}
.feature7-block {
  margin-bottom: 56px;
}
.feature7-block-group {
  display: block;
  padding: 28px 24px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  background-image: url('https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*fMOFSpRXMxsAAAAAAAAAAABkARQnAQ');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature7-block-group:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}
.feature7-block-image {
  float: left;
  width: 24px;
}
.feature7-block-title {
  font-size: 14px;
  float: left;
  margin-left: 8px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.feature7-block-content {
  clear: both;
  color: rgba(0, 0, 0, 0.75);
}
@media screen and (max-width: 767px) {
  .feature7-block {
    margin-bottom: 32;
  }
  .feature7-wrapper.home-page-wrapper .home-page {
    padding: 32px 24px;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 64px;
}
.content0-wrapper .content0 > .title-wrapper span {
  z-index: 9;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 125%;
  display: inline-block;
  color: #000000;
  position: relative;
}
.content0-wrapper .content0 .title-wrapper-text {
  max-width: 426px;
  text-align: center;
  position: relative;
  margin: 0 auto 64px;
}
.content0-wrapper .content0 .title-wrapper-text-right {
  text-align: right;
  position: relative;
  max-width: 322px;
  margin-bottom: 60px;
  display: inline-block;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon1 {
  width: auto;
  height: 122px;
  margin: auto;
  position: relative;
}
.content0-wrapper .content0-block-icon1 img {
  width: auto;
  height: 100%;
}
.content0-wrapper .content0-block-icon1-img1 {
  position: absolute;
  left: -15%;
  width: auto;
  height: 122px;
}
.content0-wrapper .content0-block-icon1-img2 {
  width: auto !important;
  height: 122px !important;
}
.content0-wrapper .content0-block-icon1-img3 {
  position: absolute;
  left: -5%;
  width: auto;
  height: 122px;
}
.content0-wrapper .content0-block-icon2 {
  width: auto;
  height: 120px;
  margin: auto;
  position: relative;
}
.content0-wrapper .content0-block-icon2 img {
  width: auto;
  height: 100%;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 12px auto;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
}
.content0-wrapper .content0-block-desc {
  padding: 0 5px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}
.content0-wrapper .content0-block-div {
  margin-bottom: 124px !important;
}
.content0-wrapper .content0-text-icon {
  position: absolute;
  left: 70px;
  width: 388px;
  height: 67.5px;
  z-index: 8;
}
.content0-wrapper .content0-text-icon1 {
  position: absolute;
  left: -21px;
  width: 252px;
  height: 48px;
  bottom: -7px;
  z-index: 8;
}
.content0-wrapper .content0-text-icon2 {
  position: absolute;
  width: 201px;
  height: 36px;
  left: 33%;
  top: 23px;
}
.content0-wrapper .content0-text {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  max-width: 990px;
  color: #000000;
  margin: 0 auto 60px;
}
.content0-wrapper .content0-nopadding {
  padding: 0 auto 58px;
  background: #fffcfa;
}
.content0-wrapper-color {
  background-color: #fffcfa;
}
.content0-wrapper-color-img {
  position: absolute;
  width: 533px;
  height: 526px;
}
.content0-wrapper-color-imgl {
  bottom: 0;
  left: 0;
}
.content0-wrapper-color-imgr {
  top: 0;
  right: 0;
}
.bgNone {
  background: none !important;
}
@media screen and (max-width: 767px) {
  .content0-wrapper .content0-block-icon1-img1 {
    position: relative;
    left: 0;
    width: 100% !important;
  }
  .content0-wrapper .content0-block-icon1-img3 {
    position: relative;
  }
  .content0-wrapper .content0 {
    height: 100%;
    padding: 44px 24px;
  }
  .content0-wrapper .content0 > .title-wrapper {
    margin: 0 auto 12px;
  }
  .content0-wrapper .content0 > .title-wrapper span {
    font-size: 24px;
    line-height: 125%;
  }
  .content0-wrapper .content0 .title-wrapper-text {
    margin: 0 auto 12px;
  }
  .content0-wrapper .content0-text-icon {
    width: 170px;
    height: 34px;
    left: 110px;
    top: 10px;
  }
  .content0-wrapper .content0-text-icon1 {
    position: absolute;
    left: -9px;
    width: 110px;
    height: 20px;
    bottom: 6px;
    z-index: 8;
  }
  .content0-wrapper .content0-text {
    font-size: 12px;
    line-height: 150%;
    margin: 0 auto 27px;
  }
  .content0-wrapper .content0 .title-wrapper {
    margin: 0 !important;
  }
  .content0-wrapper .content0-block-desc {
    font-size: 12px;
    line-height: 150%;
  }
  .content0-wrapper .content0-block-icon1-img1 {
    position: absolute;
    left: -5%;
    width: auto;
    height: 122px;
  }
  .content0-wrapper .content0-block-icon1-img2 {
    width: auto !important;
    height: 102px !important;
    margin: auto;
  }
  .content0-wrapper .content0-block-icon1-img3 {
    position: absolute;
    left: -5%;
    width: auto;
    height: 122px;
  }
  .content0-wrapper .content0-text-icon2 {
    position: absolute;
    width: 120px;
    height: 20px;
    left: 35%;
    top: 6px;
  }
  .bgNone {
    padding: 0 24px !important;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 32px auto 16px !important;
  font-size: 24px;
  min-height: 64px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper .content0-block-wrapper {
    padding: 0 !important;
  }
  .content0-wrapper .content0-block img {
    width: 100% ;
    height: auto ;
  }
  .content0-wrapper .content0-block-title {
    font-size: 15px !important;
    line-height: 21px !important;
    margin: 16px 0 !important;
    min-height: auto !important;
  }
}
.teams0-wrapper {
  height: 430px;
}
.teams0-wrapper .teams0 {
  padding: 0 54px 0 112px;
  display: flex;
  align-items: flex-end;
  margin-top: 80px !important;
}
.teams0-wrapper .teams0 .banner-anim {
  width: 100%;
  height: 100%;
}
.teams0-wrapper .teams0 .banner-anim-thumb span {
  background: #e9e9e9;
  box-shadow: none;
}
.teams0-wrapper .teams0 .banner-anim-thumb span.active {
  background: #A01E28;
}
.teams0-wrapper .teams0 .queue-anim-leaving {
  position: relative !important;
}
.teams0-wrapper .teams0-banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.teams0-wrapper .teams0-image {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.teams0-wrapper .teams0-image img {
  height: 100%;
}
.teams0-wrapper .teams0-content {
  font-size: 12px;
  color: #919191;
  text-align: center;
  width: 80%;
  margin: 8px auto;
}
.teams0-wrapper .teams0-h1 {
  font-size: 24px;
  text-align: center;
  width: 80%;
  margin: 24px auto 0;
}
@media screen and (max-width: 767px) {
  .teams0-wrapper {
    min-height: 480px;
    display: block;
  }
  .teams0-wrapper .teams0 {
    display: block;
    padding: 0 !important;
  }
  .teams0-wrapper .teams0-content-wrapper {
    padding: 0 54px;
  }
}
.content5-wrapper {
  background-color: #fafafa;
  min-height: 720px;
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  background: #fff;
  display: block;
  border-radius: 10px 10px 0px 0px;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: none;
  color: rgba(0, 0, 0, 0.85);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #e9e9e9;
  padding: 5%;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
}
.content12-wrapper {
  background-color: #fafafa;
  min-height: 470px;
}
.content12-wrapper .content12 {
  padding: 64px 24px;
}
.content12-wrapper .content12 > p {
  text-align: center;
}
.content12-wrapper .img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content12-wrapper .img-wrapper .block {
  margin-bottom: 40px;
}
.content12-wrapper .img-wrapper .block .block-content {
  display: flex;
  border-radius: 4px;
  text-align: center;
  position: relative;
  border: none;
  height: 34px;
  align-items: center;
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content12-wrapper .img-wrapper .block .block-content > span {
  height: 100%;
  display: block;
}
.content12-wrapper .logo {
  cursor: pointer;
  transition: all 0.6s;
}
.content12-wrapper .logo:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 767px) {
  .content12-wrapper {
    overflow: hidden;
  }
  .content12-wrapper .content12 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content12-wrapper .content12 ul li span {
    height: 168px;
  }
}
.footer1-wrapper {
  background: #fffdfa;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #090914;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #A01E28;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: left;
  line-height: 80px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #090914;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .block .logo {
    max-width: 100%;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
@font-face {
  font-family: 'DM Serif Display';
  src: url('../../Font/DMSerifDisplay-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'HK Grotesk';
  src: url('../../Font/TTF/HKGrotesk-Regular.ttf') format('truetype');
}
#Feature7_0.l3a19qads6q-editor_css {
  background-color: #1d0563;
}
#Feature7_0 .home-page > .feature7-title-wrapper > .l3a19wqwtz9-editor_css {
  max-width: 778px;
  margin: auto;
  margin-bottom: 32px;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 114%;
  text-align: center;
  color: #fefcfe;
}
#Feature7_0 .ant-col > .feature7-block-group > .l3a1aztmv8i-editor_css {
  color: #fbfbfb;
}
#Feature7_0 .ant-col > .feature7-block-group > .l3a29x6n4v-editor_css {
  justify-content: center;
  float: right;
  clear: right;
}
.l3a2gn3d8t1-editor_css {
  background-color: #1d0563;
  min-height: 830px !important;
  position: relative;
  width: 100%;
}
#Content0_0.l3a2gn3d8to-editor_css {
  background-color: #fffcfa;
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg3.png');
  background-size: 100% 100%;
  min-height: 830px;
  width: 100%;
}
#Feature0_0 .home-page > .title-wrapper > .l3a2i56xfnu-editor_css {
  text-align: right;
}
#Feature0_0 .ant-col > .content0-block-item > .l3a2j5955u-editor_css {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
}
#Feature0_0 .ant-col > .content0-block-item > .l3a2kt4hyx-editor_css {
  margin: auto auto 12px;
}
#Feature0_0 .ant-col > .content0-block-item > .l3a2kt4hyx-editor_css_content {
  max-width: 255px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: #000000;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 2px auto;
}
#Feature0_0 .ant-col > .content0-block-item > .l3a2kt4hyx-editor_css img {
  width: 120px;
  height: auto;
}
#Content5_0.l3a2r4x6ks-editor_css {
  background-color: #fff;
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg6.png');
  background-size: 100% 100%;
  background-position: -33px 137px;
  background-repeat: no-repeat;
}
.l3a2r4x6ks-editor_css_icon {
  width: 60px;
}
#Content5_0 .ant-col > .content5-block-content > .l3a2rjapme-editor_css {
  text-align: left;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #263959;
  padding: 16px 12px;
}
#Banner5_0.l3a2wojaw29-editor_css {
  background-color: rgba(0, 0, 0, 0);
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg2.png'), linear-gradient(to top, #01010e 100%, rgba(193, 218, 255, 0.3) 100%);
  background-attachment: scroll, scroll;
  background-blend-mode: normal, normal;
  background-position: center center, 0% 0%;
  background-repeat: repeat, repeat;
  background-size: cover, auto;
  background-clip: padding-box, padding-box;
}
#Banner5_0 .home-page > .l3a30yde5-editor_css {
  max-width: 499px;
  right: -31px !important;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .l3a32t68bt-editor_css {
  font-size: 22px;
  color: #fff;
  max-width: 538px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
#Banner5_0 .l3a33ekuwj-editor_css {
  width: 100%;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .l3a31uq47eq-editor_css {
  font-family: DM Serif Display;
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 103.5%;
  text-transform: capitalize;
  color: #f9faf8;
  margin: 0px 0px 50px;
}
#Feature7_0 .home-page > .feature7-title-wrapper > .l3a1ads3x8-editor_css {
  color: #fbfbfb;
  margin: 0 auto 54px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #fcfcfc;
  max-width: 990px;
}
#Feature7_0 .ant-col > .feature7-block-group > .l3a1at126t8-editor_css {
  font-size: 28px;
}
#Feature7_0 .ant-col > .feature7-block-group > .l3a1at126t8-editor_css img {
  width: 64px;
  height: 64px;
  margin: 0 auto 10px;
  display: none;
}
#Feature7_0 .ant-col > .feature7-block-group > .l3a1at126t8-editor_css:hover img {
  width: 64px;
  height: 64px;
  display: block;
}
#Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fbfbfb;
  text-align: center;
  max-height: 194px;
  min-height: 194px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: #fbfbfb;
  border-right-color: #fbfbfb;
  border-bottom-color: #fbfbfb;
  border-left-color: #fbfbfb;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 0px 73px;
}
#Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css .l3a1at126t8-editor_css {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  color: #fff !important;
}
#Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css:hover {
  background-color: #fbfbfb;
}
#Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css:hover .l3a1at126t8-editor_css {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  color: #0a0a22 !important;
}
#Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css:hover img {
  width: 64px;
  height: 64px;
  display: block;
}
#Nav2_0.l3a3ta9q51-editor_css {
  background-color: #fff;
}
#Nav2_0 .home-page > .header2-menu > .l3a3tmh4g8e-editor_css {
  color: #a01e28;
  text-align: center;
}
#Nav2_0 .home-page > .header2-menu > .l3a3tmh4g8e-editor_css:hover {
  border-bottom-color: #a01e28;
}
#Nav2_0 .l3a3u4xwzw7-editor_css {
  display: flex;
}
#Nav2_0 .home-page > .l3a3ua3ic0j-editor_css {
  margin: 0 94px 0 0;
}
#Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l3a40zlrxyl-editor_css {
  font-size: 28px;
  line-height: 54px;
  color: #000000;
  text-align: left;
  width: 100% !important;
}
#Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l3a40nbbtr-editor_css {
  font-size: 88px;
  text-align: left;
  width: 100% !important;
}
#Teams0_0.l3a40ov85a6-editor_css {
  height: 827px;
  background-color: #fffcfa;
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg5.png');
  background-size: 100% 100%;
  width: 100%;
}
#Teams0_0.l3a40ov85a6-editor_css .bg31_d {
  height: 747px;
  width: 1200px;
  position: absolute;
  left: 50%;
  margin-left: -600px;
  will-change: transform;
  display: flex;
}
#Teams0_0.l3a40ov85a6-editor_css .bg31_m {
  height: 100%;
  width: auto;
  margin-top: 80px;
}
@media screen and (max-width: 900px) {
  #Teams0_0.l3a40ov85a6-editor_css .bg31_d {
    height: 500px;
    left: 72%;
  }
  #Teams0_0.l3a40ov85a6-editor_css .bg31_m {
    margin-top: 40px;
  }
  .l3a2gn3d8t1-editor_css {
    min-height: 330px  !important;
  }
}
@media screen and (max-width: 1000px) {
  #Teams0_0.l3a40ov85a6-editor_css .bg31_d {
    height: 500px;
    left: 72%;
  }
  #Teams0_0.l3a40ov85a6-editor_css .bg31_m {
    margin-top: 40px;
  }
  #Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l3a40nbbtr-editor_css {
    font-size: 48px;
  }
  #Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l3a40zlrxyl-editor_css {
    font-size: 18px;
    line-height: 28px;
  }
  #Teams0_0.l3a40ov85a6-editor_css {
    height: 600px;
  }
}
#Content5_0 .home-page > .title-wrapper > .l3a44ohw8bn-editor_css {
  max-width: 1007px;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  /* or 58px */
  margin: 0 auto;
  text-align: center;
  color: #000000;
}
#Content5_0 .l3a2ryo598-editor_css {
  text-align: center;
}
#Content5_0 .ant-col > .content5-block-content > .l3a4788p8q-editor_css {
  height: 158px;
  padding: 0;
  background: none;
}
#Content5_0 .ant-col > .content5-block-content > .l3a4788p8q-editor_css img {
  max-width: 100%;
}
#Footer1_1 .l3a4dh6vxnu-editor_css {
  background-color: #fff;
}
.l3a4dda9nwb-editor_css {
  text-align: left;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #090914;
}
#Footer1_1 .footer1 > .l3a4e5y5if-editor_css {
  display: flex;
  justify-content: space-between !important;
}
.l3a4e5y5if-editor_css {
  display: flex;
  justify-content: space-between !important;
}
.l3a4e5y5if-editor_css .logo_div {
  display: flex;
}
.l3a4e5y5if-editor_css .logo_div img {
  width: 24px;
  height: 24px;
  margin: 16px 32px 47px 0;
}
.l3a4e5y5if-editor_css .slogan .slogan-div {
  display: flex;
}
.l3a4e5y5if-editor_css .slogan .slogan-span {
  margin-right: 44px;
  margin-bottom: 56px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #090914;
  display: inline-block;
}
#Footer1_1 .footer1 > .ant-row > .l3a4eq60zw-editor_css {
  color: #090914;
}
#Footer1_1 .ant-col > div > .l3a4evprfp-editor_css {
  color: #090914;
}
#Footer1_1 .ant-row > .ant-col > .l3a4fca48nc-editor_css {
  color: #090914;
}
#Footer1_1 .ant-row > .ant-col > .l3a4fca48nc-editor_css img {
  max-width: 287px;
  width: 100%;
}
.l3a4fca48nc-editor_css {
  color: #090914;
  text-align: left;
}
.l3a4fca48nc-editor_css img {
  max-width: 287px;
  width: 100%;
}
#Footer1_1.l3a4fjofzrg-editor_css {
  background-color: #fff;
}
#Content12_0.l3a48v6nzyf-editor_css {
  background-color: #0d1729;
}
#Content12_0.l3a48v6nzyf-editor_css .block-content img {
  margin-right: 46px;
  height: 36px;
}
@media screen and (max-width: 767px) {
  #Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l3a40zlrxyl-editor_css {
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: #000000;
  }
  #Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l3a40nbbtr-editor_css {
    font-size: 24px;
  }
  #Teams0_0.l3a40ov85a6-editor_css {
    height: 615px;
    padding: 0px 0px !important;
    margin-top: 0 !important;
  }
  #Teams0_0.l3a40ov85a6-editor_css .bg31_d {
    height: 515px;
    width: 100%;
    position: absolute;
    left: 0;
    will-change: transform;
    display: flex;
    margin: 0;
  }
  #Teams0_0.l3a40ov85a6-editor_css .bg31_m {
    width: 100%;
    margin-top: 40px;
  }
  #Content5_0.l3a2r4x6ks-editor_css {
    background-color: #fff;
    background-image: url('../../../public/images/bg6_m.png');
    background-size: 100%;
    background-position: 0px 188px;
    background-repeat: no-repeat;
    z-index: 1;
  }
  #Content5_0 .ant-col > .content5-block-content > .l3a2rjapme-editor_css {
    text-align: left;
  }
  .l3a4e5y5if-editor_css {
    padding: 104px 30px 47px !important;
  }
  .l3a4e5y5if-editor_css .logo {
    max-width: 100%;
  }
  #Content0_0.l3a2gn3d8to-editor_css {
    padding-bottom: 100px;
  }
  #Content0_0.l3a2gn3d8to-editor_css .home-page {
    padding: 44px 24px !important;
  }
  #Banner5_0 .home-page > .banner5-title-wrapper > .l3a31uq47eq-editor_css {
    font-size: 23px;
    line-height: 103.5%;
    margin: 0px 0px 25px;
    position: relative;
    z-index: 9;
  }
  #Banner5_0 .home-page > .banner5-title-wrapper > .l3a32t68bt-editor_css {
    font-size: 12px;
    line-height: 140%;
    max-width: 400px;
    position: relative;
    z-index: 9;
  }
  #Banner5_0 .home-page > .l3a30yde5-editor_css {
    width: 135px;
    height: 134px;
    position: absolute;
    top: 19px;
    right: 15px !important;
    z-index: 0;
  }
  #Feature7_0 .home-page > .feature7-title-wrapper > .l3a19wqwtz9-editor_css {
    font-size: 24px;
    line-height: 114%;
    margin-bottom: 12px;
  }
  #Feature7_0 .home-page > .feature7-title-wrapper > .l3a1ads3x8-editor_css {
    font-size: 11px;
    line-height: 150%;
    margin-bottom: 36px;
  }
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css,
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css:hover {
    padding: 0;
    max-height: 112px;
    min-height: 112px;
  }
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css,
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css:hover {
    background-color: #fbfbfb;
  }
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css .l3a1at126t8-editor_css,
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css:hover .l3a1at126t8-editor_css {
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #0a0a22 !important;
  }
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css img,
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css:hover img {
    width: 38px;
    height: 38px;
    display: block;
    margin-bottom: 10px;
  }
  #Feature0_0 .ant-col > .content0-block-item > .l3a2j5955u-editor_css {
    font-size: 32px !important;
    line-height: 48px !important;
  }
  #Content5_0 .home-page > .title-wrapper > .l3a44ohw8bn-editor_css {
    font-size: 24px;
    line-height: 120%;
  }
  .l3a2r4x6ks-editor_css_icon {
    width: 30px;
  }
  #Content5_0.l3a2r4x6ks-editor_css .home-page {
    padding: 44px 24px !important;
  }
  #Content12_0.l3a48v6nzyf-editor_css .home-page {
    padding: 44px 30px !important;
  }
  #Content12_0.l3a48v6nzyf-editor_css .home-page .img-wrapper {
    display: flex;
    flex-flow: row wrap;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  #Content12_0.l3a48v6nzyf-editor_css .home-page .img-wrapper .block {
    margin-right: 10px;
    margin-bottom: 24px;
  }
  #Content12_0.l3a48v6nzyf-editor_css .block-content img {
    margin-right: 5px;
    height: 28px;
  }
  .block-content img {
    height: 100%;
  }
  .block-content span {
    display: inline-block;
  }
  .slogan .slogan-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .slogan .slogan-span {
    margin-bottom: 32px !important;
  }
  .l3a4eq60zw-editor_css {
    position: absolute;
    top: 30px;
    left: 30px;
  }
  .l3a4eq60zw-editor_css img {
    width: 152px;
    height: auto;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  position: relative;
  top: 0;
}
.header3 .lang {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 999;
  margin-top: -11px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #000000;
  cursor: pointer;
}
.header3 .lang span {
  display: inline-block;
  margin: 0 5px;
}
.header3 .home-page {
  padding: 0 24px;
  display: flex;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
  margin-right: 60px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  flex: 1;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
.header3 .ant-menu-horizontal > .ant-menu-item::after,
.header3 .ant-menu-horizontal > .ant-menu-submenu::after {
  bottom: 15px !important;
}
.header3 .ant-menu-item-active::after {
  bottom: 15px;
  border-bottom: 2px solid #a01e28;
  animation-name: slit-in-horizontal;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}
@keyframes slit-in-horizontal {
  0% {
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  50% {
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    transform: translateZ(0) rotateX(0);
  }
}
@media screen and (max-width: 767px) {
  .header3 .lang {
    right: 60px;
  }
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
