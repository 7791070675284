@content0: content0;

.@{content0}-wrapper {
  min-height: 446px;
  overflow: hidden;

  .@{content0} {
    height: 100%;
    padding: 64px 24px;

    >.title-wrapper {
      margin: 0 auto 48px;
    }

    &-block {
      padding: 0 4%;
      display: inline-block;
      text-align: center;
      min-height: 200px;
      margin-bottom: 24px;
      img {
        width: 100%;
      }

      &-wrapper {
        position: relative;
        height: 100%;
        top: 25%;
        padding: 20px 0;
      }

      &.queue-anim-leaving {
        position: relative !important;
      }

      &-icon {
        width: 100px;
        height: 100px;
        margin: auto;
      }

      &-title {
        line-height: 32px;
        margin: 32px auto 16px !important;
        font-size: 24px;
        min-height: 64px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content0}-wrapper {
    // min-height: 880px;
    .@{content0}{
      &-block {
        &-wrapper {
          padding:  0 !important;
        }
        img{
          width: 100% ;
          height: auto ;
        }
        
        &-title {
          font-size: 15px !important;
          line-height: 21px !important;;
          margin: 16px 0 !important;
          min-height: auto !important
        }
      }
    }
  
  }
}
