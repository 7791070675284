body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
  z-index: 999;
}
.home-page-wrapper .philosophy {
  max-width: 100% !important;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page-wrapper .content3-icon {
  width: 64px !important;
  height: 64px;
  object-fit: cover;
  margin-bottom: 32px;
}
.home-page-wrapper .content3-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-page-wrapper .content3-block {
  display: flex !important;
  padding: 32px 20px 0 !important;
  vertical-align: top;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 230px;
  height: 284px;
  margin-right: 12px;
  border-radius: 12px;
  transition: transform 0.2s, box-shadow 0.2s, background 0.2s;
  will-change: transform, box-shadow, background;
  cursor: pointer;
}
.home-page-wrapper .content3-block:focus:before,
.home-page-wrapper .content3-block:hover:before {
  border-color: #242424;
}
.home-page-wrapper .content3-block::before {
  content: '';
  display: block;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: inherit;
  border: 1px solid rgba(36, 36, 36, 0.1);
  transition: border-color 0.15s;
  border-color: transparent;
}
.home-page-wrapper .content3-block:hover {
  background-color: #fff;
  transform: translate(6px, -6px);
  box-shadow: -3px 3px 0 -1px #fff, -3px 3px 0 0 #242424, -6px 6px 0 -1px #fff, -6px 6px 0 0 #242424;
}
.home-page-wrapper .block1-bg {
  background-color: #fef4e8;
}
.home-page-wrapper .block2-bg {
  background-color: #fedcdc;
}
.home-page-wrapper .block3-bg {
  background-color: #e3fff3;
}
.home-page-wrapper .block4-bg {
  background-color: #e3f8ff;
}
.home-page-wrapper .block5-bg {
  background-color: #f1f6e9;
}
.home-page-wrapper .block6-bg {
  background-color: #ede7fa;
}
.home-page-wrapper .content3-block-wrapper {
  min-width: 1452px;
  margin: 0 5%;
}
.home-page-wrapper .line {
  position: absolute;
  left: 0;
}
.home-page-wrapper .svg-row,
.home-page-wrapper .content3-block-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.home-page-wrapper .svg-row .svg,
.home-page-wrapper .content3-block-wrapper .svg {
  margin: 32px 9px;
  cursor: pointer;
}
.home-page-wrapper .content3-text {
  display: inline-block;
  position: relative;
  padding: 0 !important;
}
.home-page-wrapper .content3-text span {
  z-index: 9;
  position: relative;
}
.home-page-wrapper .content3-text-icon {
  position: absolute;
  width: 306px;
  height: 46px;
  left: -26px;
  top: 15px;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
  .home-page-wrapper .philosophy {
    padding: 44px 24px !important;
  }
  .home-page-wrapper .content3-text-icon {
    position: absolute;
    width: 160px;
    height: 29px;
    left: -12px;
    top: 3px;
  }
  .home-page-wrapper .content3-block {
    width: 158px;
    height: 190px;
    padding: 16px !important;
    flex-shrink: 0;
  }
  .home-page-wrapper .content3-icon {
    width: 22px !important;
    height: 22px;
    object-fit: cover;
    margin-bottom: 7px;
  }
  .home-page-wrapper .content3-block-wrapper {
    width: 100%;
    overflow-x: auto;
    min-width: 100%;
    justify-content: flex-start;
    padding: 10px 0;
  }
  .home-page-wrapper .line {
    width: 113px;
    left: 24px;
  }
  .home-page-wrapper .svg-row,
  .home-page-wrapper .content3-block-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .home-page-wrapper .svg-row .svg,
  .home-page-wrapper .content3-block-wrapper .svg {
    margin: 12px 9px;
    cursor: pointer;
    width: 22px;
    height: 22px;
  }
  .home-page-wrapper .title-wrapper {
    margin: 0 auto !important;
  }
  .home-page-wrapper .title-wrapper > h1,
  .home-page-wrapper > h1 {
    font-size: 24px !important;
    line-height: 24px;
    margin-bottom: 12px !important;
  }
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.banner1 {
  width: 100%;
  height: 100vh;
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner1-wrapper,
.banner1 .banner-anim {
  height: 100%;
}
.banner1 .queue-anim-leaving {
  position: relative !important;
}
.banner1 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner1 .bg0 {
  background: #eff0fc;
}
.banner1 .bg1 {
  background: #eff7fc;
}
.banner1 .bg2 {
  background: #f6effc;
}
.banner1 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner1 .banner1-title-text {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 125%;
  text-align: right;
  color: #000000;
  margin-bottom: 17px;
}
.banner1 .banner1-title-text span {
  position: relative;
  z-index: 9;
}
.banner1 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner1 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner1 .banner1-text-icon {
  position: absolute;
  right: 37px;
  width: 313px;
  top: 21px;
  height: 46px;
}
.banner1 .banner1-text-wrapper {
  display: block;
  position: relative;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  color: #fff;
  max-width: 1200px;
  width: 100%;
  height: 500px;
  text-align: right;
}
.banner1 .banner1-title {
  display: inline-block;
  text-align: right;
  position: relative;
}
.banner1 .banner1-content {
  margin-bottom: 54px;
  word-wrap: break-word;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  text-align: right;
  color: #000000;
}
.banner1 .banner1-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner1 .banner1-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner1 .banner1-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner1 .banner1-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner1 .banner1-button.queue-anim-leaving {
  width: auto;
}
.banner1 .banner1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner1-block {
  min-height: 512px;
}
.banner1-block-div {
  width: 100%;
  min-height: 512px;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  padding: 0 34px;
}
.banner1 .div1 {
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg9.png');
  background-size: 100% 100%;
}
.banner1 .div2 {
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg10.png');
  background-size: 100% 100%;
}
.banner1 .div3 {
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg11.png');
  background-size: 100% 100%;
}
.banner1-block-title {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 125%;
  text-align: right;
  color: #000000;
  max-width: 585px;
}
.banner1-block-img {
  width: 352px;
  height: 352px;
  object-fit: cover;
  flex-shrink: 0;
}
.banner1-block-img img {
  width: 100%;
  height: 100%;
}
.banner-anim-thumb-default span {
  opacity: 0.2;
  width: 24px;
  height: 4px;
  background: #a01e28;
  border-radius: 1px;
}
.banner-anim-thumb-default .active {
  opacity: 1;
  background: #a01e28 !important;
}
.banner-anim-thumb {
  bottom: 15%;
}
@media screen and (max-height: 1000px) {
  .banner-anim-thumb {
    bottom: 15%;
  }
}
@media screen and (max-height: 900px) {
  #bgimg1 {
    display: none !important;
  }
  .banner-anim-thumb {
    bottom: 0%;
  }
}
@media screen and (max-height: 800px) {
  .banner-anim-thumb {
    bottom: 0%;
  }
}
@media screen and (max-width: 767px) {
  #bgimg1 {
    display: block !important;
  }
  .banner1 {
    height: 675px;
  }
  .banner1 .banner1-text-wrapper {
    width: 90%;
  }
  .banner1 .banner1-text-wrapper .banner1-title {
    width: 90%;
    left: 0;
  }
  .banner1 .bg {
    background-attachment: inherit;
  }
  .banner1 .banner1-title-text {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .banner1 .banner1-text-icon {
    position: absolute;
    right: 44px;
    width: 138px;
    top: 8px;
    height: 20px;
    left: auto;
  }
  .banner1 .banner1-content {
    margin-bottom: 36px;
    font-size: 12px;
    line-height: 12px;
  }
  .banner1-block-title {
    font-size: 15px;
    line-height: 125%;
    margin-bottom: 5px;
    width: 100%;
    text-align: right;
  }
  .banner1-block-div {
    width: 100%;
    min-height: 365px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    flex-direction: column;
  }
  .banner1-block-img {
    width: 204px;
    height: 204px;
  }
  .banner1 .div1 {
    background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg9_m.png');
    background-size: 100% 100%;
  }
  .banner1 .div2 {
    background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg10_m.png');
    background-size: 100% 100%;
  }
  .banner1 .div3 {
    background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg11_m.png');
    background-size: 100% 100%;
  }
  .banner-anim-thumb {
    bottom: 180px;
  }
}
.content0-wrapper {
  min-height: 446px;
  position: relative;
  overflow: inherit !important;
}
.content0-wrapper-img {
  position: absolute;
  width: 100%;
  bottom: -30px;
  z-index: 99;
  height: 150px;
  object-fit: cover;
}
.content0-wrapper-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
  position: relative;
  z-index: 9;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 112px;
  height: 102px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 32px auto;
  font-size: 20px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 500;
  line-height: 144%;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 767px) {
  .content0-wrapper .home-page {
    padding: 0 24px !important;
    position: relative;
    z-index: 999;
  }
  .content0-wrapper .content0 > .title-wrapper {
    font-size: 24px;
    line-height: 24px;
  }
  .content0-wrapper .content0-block {
    min-height: 132px;
  }
  .content0-wrapper .content0-block-icon {
    width: 94px;
    height: 94px;
  }
  .content0-wrapper .content0-block-title {
    font-size: 12px !important;
    line-height: 18px !important;
    margin-top: 0 !important;
  }
}
#Feature3_0 .home-page > .title-wrapper > .l3jvhfbfzzd-editor_css {
  font-size: 48px;
  line-height: 125%;
  color: #000;
  margin: 0px 0 32px;
}
#Feature3_0 .ant-col > .content3-text > .l3jvj217fbt-editor_css {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 137.69%;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 14px;
}
#Feature3_0 .ant-col > .content3-text > .l3jvji77ws-editor_css {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
  color: #000000;
}
.home-page > .title-wrapper > .l3jvi2ssiz-editor_css {
  display: inline-block;
  max-width: 608px;
  position: relative;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}
.l3jvi2ssiz {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 767px) {
  #Feature3_0 .home-page > .title-wrapper > .l3jvhfbfzzd-editor_css {
    font-size: 24px;
    line-height: 125%;
    margin-bottom: 12px;
  }
  .home-page > .title-wrapper > .l3jvi2ssiz-editor_css {
    font-size: 12px;
    line-height: 150%;
  }
  #Feature3_0 .ant-col > .content3-text > .l3jvj217fbt-editor_css {
    font-size: 15px;
    line-height: 137.69%;
    margin-bottom: 6px;
  }
  #Feature3_0 .ant-col > .content3-text > .l3jvji77ws-editor_css {
    font-size: 9px;
    line-height: 140%;
  }
  .l3jvi2ssiz {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 16px !important;
  }
}
