@header3: header3;

.@{header3} {
  background: #fff;
  width: 100%;
  z-index: 1;
  // box-shadow: 0 4px 6px fade(#000, 5);
  position: relative;
  top: 0;
  .lang {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 999;
    margin-top: -11px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    color: #000000;
    cursor: pointer;
    span {
      display: inline-block;
      margin: 0 5px;
    }
  }
  .home-page {
    padding: 0 24px;
    display: flex;
  }

  &-logo {
    display: inline-block;
    position: relative;
    width: 150px;
    line-height: 64px;
    margin-right: 60px;

    & img {
      vertical-align: middle;
      display: inline-block;
    }

    & a {
      display: block;
    }
  }

  &-menu {
    // float: right;
    flex: 1;
    .ant-menu-horizontal {
      border-bottom: none;
    }
    .ant-menu {
      line-height: 62px;
      height: 64px;
      background: transparent;

      a {
        display: block;
      }
    }
  }

  &-item {
    &-block {
      padding: 0 8px;

      > * {
        display: inline-block;
      }
    }
  }

  &-item,
  &-item-child,
  &-menu {
    .ant-menu-sub .ant-menu-item,
    .ant-menu-inline .ant-menu-item {
      height: auto;
      line-height: 1.5;
    }

    .item {
      &-sub-item {
        display: block;
        padding: 8px 24px;
      }

      &-image {
        float: left;
        margin-right: 16px;
        margin-top: 4px;
        position: relative;
        z-index: 1;
      }

      &-title {
        font-size: 14px;
        color: @text-color;
        margin-left: 46px;
      }

      &-content {
        font-size: 12px;
        color: fade(@text-color, 75);
        margin-left: 46px;
      }
    }
  }
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    bottom: 15px !important;
  }

  .ant-menu-item-active::after {
    bottom: 15px;
    // height: 2px;
    border-bottom: 2px solid #a01e28;
    animation-name: slit-in-horizontal;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
  }
  @keyframes slit-in-horizontal {
    0% {
      transform: translateZ(-800px) rotateY(90deg);
      opacity: 0;
    }
    50% {
      transform: translateZ(-160px) rotateY(87deg);
      opacity: 1;
    }
    100% {
      transform: translateZ(0) rotateX(0);
    }
  }
}

@media screen and (max-width: 767px) {
  .@{header3} {
    .lang {
      right: 60px;
    }
    &-logo {
      z-index: 101;
    }

    &.home-page-wrapper .home-page {
      padding: 0 24px;
    }

    &-menu {
      height: auto;
      float: inherit;
      position: relative;
      left: -24px;
      width: ~'calc(100% + 48px)';
      opacity: 0;
      transition: opacity 0.3s @ease-in-out;
      background: #fff;

      & li {
        padding: 0 24px;

        &.ant-menu-submenu {
          padding: 0;
        }
      }
      .item {
        &-sub-item {
          padding: 8px 0;
        }
      }
    }

    &-mobile-menu {
      width: 16px;
      height: 14px;
      cursor: pointer;
      position: absolute;
      top: 24px;
      right: 24px;
      z-index: 100;

      em {
        display: block;
        width: 100%;
        height: 2px;
        background: @template-nav-bg-color;
        margin-top: 4px;
        transition: transform 0.3s @ease-in-out, opacity 0.3s @ease-in-out;
      }

      :first-child {
        margin-top: 0;
      }
    }

    .ant-menu {
      height: auto;
      overflow: hidden;

      .ant-menu-item-selected {
        border: none;
      }
    }

    & .open {
      height: auto;

      .@{header3}-mobile-menu {
        em {
          &:nth-child(1) {
            transform: translateY(6px) rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: translateY(-6px) rotate(-45deg);
          }
        }
      }

      > .@{header3}-menu {
        opacity: 1;
        pointer-events: auto;
      }
    }
    &-item-block {
      height: 40px;
      line-height: 40px;
    }
  }
}
