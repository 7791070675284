@banner5: banner5;

.@{banner5} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(
    to top,
    fade(#fdfdfd, 30) 0%,
    fade(#c1daff, 30) 100%
  );
  overflow: hidden;
  & &-page {
    padding: 1px 0 0;
  }

  &-title {
    font-size: 48px;
    line-height: 1.5;
    color: #5f9bf1;
    margin-bottom: 8px;

    &-wrapper {
      // width: 40%;
      max-width: 786px;
      position: relative;
      z-index: 99;
      top: 25%;
      left: 24px;
      font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica,
        Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    }
  }

  &-explain {
    color: #333;
    font-size: 24px;
    margin-bottom: 24px;
  }
  .banner5-content-br {
    display: none;
  }
  &-content {
    font-size: 18px;
    color: #666;
    margin-bottom: 48px;
    position: relative;
    &-span {
      position: relative;
      display: inline-block;
    }
    &-text {
      position: relative;
      z-index: 9;
    }
    &-icon {
      // background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/icon.svg');
      // background-size: 100% 100%;
      // display: inline-block;
      // padding-bottom: 16px;
      position: absolute;
      left: -1px;
      top: 5px;
    }
  }

  &-button-wrapper {
    .@{banner5}-button {
      background-image: linear-gradient(
        to right bottom,
        #82b5ff 0%,
        #3c89f6 0%
      );
      box-shadow: 0 8px 24px fade(#5f9bf1, 35);
      border-radius: 20px;
      border: none;
      padding: 0 42px;
      line-height: 40px;
      height: 40px;
      transition: transform 0.3s, box-shadow 0.3s;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 13px 24px fade(#5f9bf1, 45);
        background-image: linear-gradient(
          to right bottom,
          #82b5ff 0%,
          #3c89f6 0%
        );
      }
    }
  }

  &-image {
    overflow: hidden;
    width: 55%;
    max-width: 710px;
    position: absolute;
    top: 15%;
    right: 24px;
  }

  &-icon-bg {
    background-color: rgba(0, 0, 0, 0);
    background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/homebg.svg'),
      linear-gradient(to top, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
    background-position: center;
    background-repeat: no-repeat;
    height: 150px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }
  &-icon {
    text-align: center;
    font-size: 18px;
    color: #fff;
    position: absolute;
    z-index: 9999;
    left: 50%;
    bottom: 70px;
    margin-left: -12px;
    cursor: pointer;
  }
  // .path {
  //   stroke-dasharray: 1000;
  //   stroke-dashoffset: 1000;
  //   animation: dash 2s linear forwards;
  // }

  // @keyframes dash {
  //   to {
  //     stroke-dashoffset: 0;
  //   }
  // }

  .path {
    stroke-dasharray: 1000;
    animation: dash 2s linear;
    animation-fill-mode: forwards;
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }

    to {
      stroke-dashoffset: 0;
    }
  }

  .banner5-bgimg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .l1 {
      animation: slashStar 2s ease-in-out 0.3s infinite;
    }
    .l2 {
      animation: slashStar 2s ease-in-out 1.2s infinite;
    }
    .l3 {
      animation: slashStar 2s ease-in-out 0.5s infinite;
    }
    .l4 {
      animation: slashStar 2s ease-in-out 0.8s infinite;
    }
    .l5 {
      animation: slashStar 2s ease-in-out 1.5s infinite;
    }
    // transform: translate(0%, 0%) rotate(0deg) scale(0.8);
    // transition: 0.1s ease-in;
    // transition-delay: .01s;
    // animation: rotate 3s infinite ease-in-out alternate;
  }
  @keyframes slashStar {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slashStar {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  // @keyframes rotate {
  //   100% {
  //     transform: translate(0%, 10%) rotate(10deg) scale(1.2);
  //   }
  // }
}

@media screen and (max-width: 767px) {
  .@{banner5} {
    height: 241px;
    & &-page.home-page {
      padding-top: 1px;
      padding-bottom: 0;
    }

    &-title-wrapper {
      text-align: left;
      position: static;
      width: 100%;
      margin-top: 64px;
      left: 0;
      top: 0;
    }

    &-title {
      font-size: 32px;
    }

    &-explain {
      font-size: 18px;
    }

    &-content {
      font-size: 14px;
    }
    .banner5-content-br {
      display: block;
    }
    &-image {
      position: static;
      width: 100%;
      top: 0;
      right: 0;
      margin: 32px 0 24px;
    }
    .banner5-content-icon {
      width: 132px;
      left: -5px;
      top: -20px;
    }
    &-icon-bg {
      height: 40px;
      background-size: 100%;
      bottom: -1px;
      z-index: 9;
    }
    &-icon {
      width: 10px;
      bottom: 0px;
      margin-left: -10px;
      img {
        width: 100%;
      }
    }
  }
}
