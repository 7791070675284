@roboto-font-path: '../../Font';
@font-face {
  font-family: 'DM Serif Display';
  src: url('@{roboto-font-path}/DMSerifDisplay-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'HK Grotesk';
  src: url('@{roboto-font-path}/TTF/HKGrotesk-Regular.ttf') format('truetype');
}

#Feature7_0.l3a19qads6q-editor_css {
  background-color: #1d0563;
}
#Feature7_0 .home-page > .feature7-title-wrapper > .l3a19wqwtz9-editor_css {
  max-width: 778px;
  font-size: 48px;
  line-height: 114%;
  margin: auto;
  margin-bottom: 32px;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 114%;
  text-align: center;
  color: #fefcfe;
}
#Feature7_0 .ant-col > .feature7-block-group > .l3a1aztmv8i-editor_css {
  color: #fbfbfb;
}
#Feature7_0 .ant-col > .feature7-block-group > .l3a29x6n4v-editor_css {
  justify-content: center;
  float: right;
  clear: right;
}


.l3a2gn3d8t1-editor_css {
  background-color: #1d0563;
  // background-image: url('../../../public/home/bg.png');
  // background-size: 100% 100%;
  min-height: 830px !important;
  position: relative;
  width: 100%;
}
#Content0_0.l3a2gn3d8to-editor_css {
  background-color: #fffcfa;
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg3.png');
  background-size: 100% 100%;
  min-height: 830px;
  width: 100%;
}
#Feature0_0 .home-page > .title-wrapper > .l3a2i56xfnu-editor_css {
  text-align: right;
}
#Feature0_0 .ant-col > .content0-block-item > .l3a2j5955u-editor_css {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
}
#Feature0_0 .ant-col > .content0-block-item > .l3a2kt4hyx-editor_css {
  margin: auto auto 12px;
  &_content {
    max-width: 255px;
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #000000;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 2px auto;
  }
  img {
    width: 120px;
    height: auto;
  }
}
#Content5_0.l3a2r4x6ks-editor_css {
  background-color: #fff;
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg6.png');
  background-size: 100% 100%;
  background-position: -33px 137px;
  background-repeat: no-repeat;
}
.l3a2r4x6ks-editor_css_icon {
  width: 60px;
}
#Content5_0 .ant-col > .content5-block-content > .l3a2rjapme-editor_css {
  text-align: left;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #263959;
  padding: 16px 12px;
}
#Banner5_0.l3a2wojaw29-editor_css {
  background-color: rgba(0, 0, 0, 0);
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg2.png'),
    linear-gradient(to top, rgb(1, 1, 14) 100%, rgba(193, 218, 255, 0.3) 100%);
  background-attachment: scroll, scroll;
  background-blend-mode: normal, normal;
  background-position: center center, 0% 0%;
  background-repeat: repeat, repeat;
  background-size: cover, auto;
  background-clip: padding-box, padding-box;
}
#Banner5_0 .home-page > .l3a30yde5-editor_css {
  max-width: 499px;
  right: -31px !important;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .l3a32t68bt-editor_css {
  font-size: 22px;
  color: #fff;
  max-width: 538px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
#Banner5_0 .l3a33ekuwj-editor_css {
  width: 100%;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .l3a31uq47eq-editor_css {
  font-family: DM Serif Display;
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 103.5%;
  text-transform: capitalize;
  color: #f9faf8;
  margin: 0px 0px 50px;
}
#Feature7_0 .home-page > .feature7-title-wrapper > .l3a1ads3x8-editor_css {
  color: rgb(251, 251, 251);
  margin: 0 auto 54px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #fcfcfc;
  max-width: 990px;
}
#Feature7_0 .ant-col > .feature7-block-group > .l3a1at126t8-editor_css {
  font-size: 28px;
  img {
    width: 64px;
    height: 64px;
    margin: 0 auto 10px;
    display: none;
  }
}
#Feature7_0 .ant-col > .feature7-block-group > .l3a1at126t8-editor_css:hover {
  img {
    width: 64px;
    height: 64px;
    display: block;
  }
}
#Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(251, 251, 251);
  text-align: center;
  max-height: 194px;
  min-height: 194px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: rgb(251, 251, 251);
  border-right-color: rgb(251, 251, 251);
  border-bottom-color: rgb(251, 251, 251);
  border-left-color: rgb(251, 251, 251);
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 0px 73px;
  .l3a1at126t8-editor_css {
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    color: #fff !important;
  }
}
#Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css:hover {
  background-color: #fbfbfb;
  .l3a1at126t8-editor_css {
    font-family: 'HK Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    color: #0a0a22 !important;
  }
  img {
    width: 64px;
    height: 64px;
    display: block;
  }
}
#Nav2_0.l3a3ta9q51-editor_css {
  background-color: #fff;
}
#Nav2_0 .home-page > .header2-menu > .l3a3tmh4g8e-editor_css {
  color: rgb(160, 30, 40);
  text-align: center;
}
#Nav2_0 .home-page > .header2-menu > .l3a3tmh4g8e-editor_css:hover {
  border-bottom-color: #a01e28;
}
#Nav2_0 .l3a3u4xwzw7-editor_css {
  display: flex;
}
#Nav2_0 .home-page > .l3a3ua3ic0j-editor_css {
  margin: 0 94px 0 0;
}
#Teams0_0
  .banner-anim-elem
  > .teams0-content-wrapper
  > .l3a40zlrxyl-editor_css {
  font-size: 28px;
  line-height: 54px;
  color: rgb(0, 0, 0);
  text-align: left;
  width: 100% !important;
}
#Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l3a40nbbtr-editor_css {
  font-size: 88px;
  text-align: left;
  width: 100% !important;
}
#Teams0_0.l3a40ov85a6-editor_css {
  height: 827px;
  background-color: #fffcfa;
  background-image: url('https://shixiangfiles.oss-cn-hangzhou.aliyuncs.com/landing/images/bg5.png');
  background-size: 100% 100%;
  width: 100%;
  .bg31_d {
    height: 747px;
    width: 1200px;
    position: absolute;
    left: 50%;
    margin-left: -600px;
    will-change: transform;
    display: flex;
  }
  .bg31_m {
    height: 100%;
    width: auto;
    margin-top: 80px;
  }
}
@media screen and (max-width: 900px) {
  #Teams0_0.l3a40ov85a6-editor_css {
    .bg31_d {
      height: 500px;
      left: 72%;
    }
    .bg31_m {
      margin-top: 40px;
    }
  }
  .l3a2gn3d8t1-editor_css {
    min-height: 330px  !important;
  }
}
@media screen and (max-width: 1000px) {
  #Teams0_0.l3a40ov85a6-editor_css {
    .bg31_d {
      height: 500px;
      left: 72%;
    }
    .bg31_m {
      margin-top: 40px;
    }
  }
  #Teams0_0
    .banner-anim-elem
    > .teams0-content-wrapper
    > .l3a40nbbtr-editor_css {
    font-size: 48px;
  }
  #Teams0_0
    .banner-anim-elem
    > .teams0-content-wrapper
    > .l3a40zlrxyl-editor_css {
    font-size: 18px;
    line-height: 28px;
  }

  #Teams0_0.l3a40ov85a6-editor_css {
    height: 600px;
  }
}
#Content5_0 .home-page > .title-wrapper > .l3a44ohw8bn-editor_css {
  font-size: 48px;
  line-height: 120%;
  max-width: 1007px;
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  /* or 58px */
  margin: 0 auto;
  text-align: center;
  color: #000000;
}
#Content5_0 .l3a2ryo598-editor_css {
  text-align: center;
}
#Content5_0 .ant-col > .content5-block-content > .l3a4788p8q-editor_css {
  height: 158px;
  padding: 0;
  background: none;
  img {
    max-width: 100%;
  }
}
#Footer1_1 .l3a4dh6vxnu-editor_css {
  background-color: #fff;
}
.l3a4dda9nwb-editor_css {
  color: #090914;
  text-align: left;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #090914;
}
#Footer1_1 .footer1 > .l3a4e5y5if-editor_css {
  display: flex;
  justify-content: space-between !important;
}
.l3a4e5y5if-editor_css {
  display: flex;
  justify-content: space-between !important;
  .logo_div {
    display: flex;
    img {
      width: 24px;
      height: 24px;
      margin: 16px 32px 47px 0;
    }
  }
  .slogan {
    .slogan-div {
      display: flex;
    }
    .slogan-span {
      margin-right: 44px;
      margin-bottom: 56px;
      font-family: 'HK Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-transform: capitalize;
      color: #090914;
      display: inline-block;
    }
  }
}
#Footer1_1 .footer1 > .ant-row > .l3a4eq60zw-editor_css {
  color: #090914;
}
#Footer1_1 .ant-col > div > .l3a4evprfp-editor_css {
  color: #090914;
}
#Footer1_1 .ant-row > .ant-col > .l3a4fca48nc-editor_css {
  color: #090914;
  img {
    max-width: 287px;
    width: 100%;
    // height: 71px;
  }
}
.l3a4fca48nc-editor_css {
  color: #090914;
  text-align: left;
  img {
    max-width: 287px;
    width: 100%;
  }
}
#Footer1_1.l3a4fjofzrg-editor_css {
  background-color: #fff;
}
#Content12_0.l3a48v6nzyf-editor_css {
  background-color: #0d1729;
  .block-content {
    img {
      // height: 34px !important;
      margin-right: 46px;
      height: 36px;
    }
  }
}

@media screen and (max-width: 767px) {
  #Teams0_0
    .banner-anim-elem
    > .teams0-content-wrapper
    > .l3a40zlrxyl-editor_css {
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: #000000;
  }
  #Teams0_0
    .banner-anim-elem
    > .teams0-content-wrapper
    > .l3a40nbbtr-editor_css {
    font-size: 24px;
  }

  #Teams0_0.l3a40ov85a6-editor_css {
    height: 615px;
    padding: 0px 0px !important;
    margin-top: 0 !important;
    .bg31_d {
      height: 515px;
      width: 100%;
      position: absolute;
      left: 0;
      will-change: transform;
      display: flex;
      margin: 0;
    }
    .bg31_m {
      width: 100%;
      margin-top: 40px;
    }
  }
  #Content5_0.l3a2r4x6ks-editor_css {
    background-color: #fff;
    background-image: url('../../../public/images/bg6_m.png');
    background-size: 100%;
    background-position: 0px 188px;
    background-repeat: no-repeat;
    z-index: 1;
  }

  #Content5_0 .ant-col > .content5-block-content > .l3a2rjapme-editor_css {
    text-align: left;
  }

  .l3a4e5y5if-editor_css {
    padding: 104px 30px 47px !important;
    .logo {
      max-width: 100%;
    }
  }

  #Content0_0.l3a2gn3d8to-editor_css {
    padding-bottom: 100px;
    .home-page {
      padding: 44px 24px !important;
    }
  }
  #Banner5_0 .home-page > .banner5-title-wrapper > .l3a31uq47eq-editor_css {
    font-size: 23px;
    line-height: 103.5%;
    margin: 0px 0px 25px;
    position: relative;
    z-index: 9;
  }
  #Banner5_0 .home-page > .banner5-title-wrapper > .l3a32t68bt-editor_css {
    font-size: 12px;
    line-height: 140%;
    max-width: 400px;
    position: relative;
    z-index: 9;
  }
  #Banner5_0 .home-page > .l3a30yde5-editor_css {
    width: 135px;
    height: 134px;
    position: absolute;
    top: 19px;
    right: 15px !important;
    z-index: 0;
  }
  #Feature7_0 .home-page > .feature7-title-wrapper > .l3a19wqwtz9-editor_css {
    font-size: 24px;
    line-height: 114%;
    margin-bottom: 12px;
  }
  #Feature7_0 .home-page > .feature7-title-wrapper > .l3a1ads3x8-editor_css {
    font-size: 11px;
    line-height: 150%;
    margin-bottom: 36px;
  }
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css,
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css:hover {
    padding: 0;
    max-height: 112px;
    min-height: 112px;
  }

  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css,
  #Feature7_0 .ant-row > .ant-col > .l3a1b2kid26-editor_css:hover {
    background-color: #fbfbfb;
    .l3a1at126t8-editor_css {
      font-family: 'HK Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      color: #0a0a22 !important;
    }
    img {
      width: 38px;
      height: 38px;
      display: block;
      margin-bottom: 10px;
    }
  }
  #Feature0_0 .ant-col > .content0-block-item > .l3a2j5955u-editor_css {
    font-size: 32px !important;
    line-height: 48px !important;
  }
  #Content5_0 .home-page > .title-wrapper > .l3a44ohw8bn-editor_css {
    font-size: 24px;
    line-height: 120%;
  }
  .l3a2r4x6ks-editor_css_icon {
    width: 30px;
  }
  #Content5_0.l3a2r4x6ks-editor_css {
    .home-page {
      padding: 44px 24px !important;
    }
  }
  #Content12_0.l3a48v6nzyf-editor_css {
    .home-page {
      padding: 44px 30px !important;
      .img-wrapper {
        display: flex;
        flex-flow: row wrap;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        .block {
          margin-right: 10px;
          margin-bottom: 24px;
        }
      }
    }
  }
  #Content12_0.l3a48v6nzyf-editor_css {
    .block-content {
      img {
        // height: 34px !important;
        margin-right: 5px;
        height: 28px;
      
      }
    }
  }
  .block-content {
    img {
      height: 100%;
    }
    span {
      display: inline-block;
    }
    // &-img0 {
    //   width: 104px;
    //   margin-right: 10px;
    // }
    // &-img1 {
    //   width: 118px;
    //   margin-right: 10px;
    // }
    // &-img2 {
    //   width: 90px;
    // }
    // &-img3 {
    //   width: 38px;
    //   margin-right: 30px;
    // }
    // &-img4 {
    //   width: 78px;
    //   margin-right: 26px;
    // }
    // &-img5 {
    //   width: 140px;
    // }
    // &-img6 {
    //   width: 108px;
    // }
    // &-img7 {
    //   width: 120px;
    // }
    // &-img8 {
    //   width: 122px;
    // }
    // &-img9 {
    //   width: 130px;
    //   margin-right: 10px;
    // }
    // &-img10 {
    //   width: 68px;
    // }
    // &-img11 {
    //   width: 107px;
    //   margin-right: 10px;
    // }
    // &-img12 {
    //   width: 99px;
    //   margin-right: 10px;
    // }
    // &-img13 {
    //   width: 107px;
    // }
  }
  .slogan {
    .slogan-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .slogan-span {
      margin-bottom: 32px !important;
    }
  }
  .l3a4eq60zw-editor_css {
    position: absolute;
    top: 30px;
    left: 30px;
    img {
      width: 152px;
      height: auto;
    }
  }
}
