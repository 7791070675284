@content5: content5;
.@{content5}-wrapper {
  background-color: #fafafa;
  min-height: 720px;
  .@{content5} {
    > p {
      text-align: center;
    }
    &-img-wrapper {
      margin: 0 auto;
      left: 0;
      right: 0;
      .block {
        margin-bottom: 24px;
        .content5-block-content {
          background: #fff;
          display: block;
          border-radius: 10px 10px 0px 0px;
          border-radius: 4px;
          padding: 10px;
          text-align: center;
          position: relative;
          overflow: hidden;
          .page-pro();
          border: none;
          color: @text-color;
          transition: box-shadow 0.3s @ease-out, transform 0.3s @ease-out;
          & > span {
            width: 100%;
            height: 160px;
            display: block;
            background: @line-color;
            padding: 5%;
          }
          & p {
            width: 100%;
            line-height: 30px;
          }
          &:hover {
            & p {
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content5}-wrapper {
    // height: 2000px;
    overflow: hidden;
    .@{content5} {
      ul {
        li {
          display: block;
          width: 100%;
          padding: 2%;
          span {
            height: 168px;
          }
          p {
            position: relative;
            bottom: 0;
          }
        }
      }
    }
  }
}
